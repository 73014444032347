//Eerst in wordt geschreven, daarna copy paste in   https://wordtohtml.net/

export const tabbr = {
    title: 'Tabbr',
    content : `

        <h1><font size="+2" color="#4472C4">Software voor sportclubs en verenigingen</font></h1>

        <p>Na de successvolle implementatie van een 'Prove of concept' bij de zweefvliegclub in Overboeleare zijn we gestart met de anaylse en ontwikkeling van een 'Software as a service' product voor verenigingen en sportclubs.</p>
        <p>Vermits geen enkele sportclub dezelfde is, is er vaak nood aan specifieke oplossingen en features. <br>De architectuur van deze software laat toe om op zeer specifieke noden in te spelen.
        <br>
        We leggen de nadruk op configureerbaarheid en uitbreidbaarheid. Gewoontes of werkwijzen van een bepaalde club worden niet zomaar als 'waarheid' aangenomen, maar kunnen wél zo geconfigureerd worden.
        <br>
        Het geheel wordt opgezet in verschillende modules, die de clubs al dan niet zelf kunnen activeren.
        </p>


        <p>Heeft je club of organisatie ook specifieke noden? Contacteer ons vrijblijvend voor een korten babbel. Misschien kunnen we iets voor jullie betekeken. <contact-button></contact-button>
        </p>

        <p><font size="+0,9"><strong>In eerste instantie implementeren we features, specifiek voor (zweef)vliegclubs. <br>
        Hieronder een greep uit de beschikbare modules:
        </strong></font>
        </p>

        <div class="image-center">
            <img src="/assets/img/ClubFox/Clubfox_img1.jpg" alt="plaatje" width="650px">
        </div>


        <p>&nbsp;</p>
        <h6>Personenbeheer + toegangsbeheer</h6>
        <p>Los van de klassieke persoonsgegevens (naam, adres, contactgegevens), kunnen clubs zelf extra velden toevoegen (bv. lid sinds xxx). Ook de zichtbaarheid binnen de club is configureerbaar. Men bepaald dus zelf wat zichtbaar is per persoon/lid.</p>
        <p>Toegangsbeheer (beveiliging) is gebaseerd op “rollen” of “machtigingen”. Deze worden gedefinieerd en toegekend.</p>
        <p>&nbsp;</p>

        <h6>Planning</h6>
        <p>De functionaliteit “Planning” is gelijkaardig als deze beschikbaar in Click'n Glide, met uitzondering van ride sharing.</p>
        <p>Dit zal voorzien worden in een aparte module.</p>
        <p>&nbsp;</p>

        <h6>Vluchtenbeheer</h6>
        <p>We voorzien hier zowel de mogelijkheid voor motorvluchten, TMG als zweefvluchten.</p>
        <p>- Toevoegen/bewerken van vluchten (manuel).</p>
        <p>- Toevoegen vluchten uit OGN (al dan niet automatisch)</p>
        <p>- Verrekenen vluchten (indien ook module billing geactiveerd werd). Hoe de kost berekend wordt, is volledig configureerbaar. De ene club kan dus een start + vluchtminuten aanrekenen, de andere club kan bv kiezen om aan te rekenen per afgerond kwartier.</p>
        <p>- Flow “Goedkeuring vluchten voordat ze verrekend worden”, indien een club dit nodig heeft.</p>
        <p>- Verrijking vluchten met extra velden (idem personenbeheer) zoals bv. initiatievlucht, kabelbreuk, kabelbreukoefening, etc.</p>
        <p>&nbsp;</p>

        <h6>Recency</h6>
        <p>Op basis van de geregistreerde vluchten kan recency info worden berekend.</p>
        <p>Naast de bestaande wettelijke recency-regelgeving, kunnen clubs hun eigen checks implementeren.</p>
        <p>Bv. aan het begin van elk seizoen moet elk lid een checkvlucht en een kabelbreukoefening gedaan hebben.</p>
        <p>&nbsp;</p>

        <h6>Startplaats</h6>
        <p>Deze module kan gebruikt worden om vanop de startplaats reeds de vluchtplank in te vullen.</p>
        <p>&nbsp;</p>

        <h6>ICE (in case of emergency)</h6>
        <p>ICE omvat het beheer van ICE contacten. Bij welke rollen is dit verplicht, mogelijkheid tot jaarlijkse herziening/bevestiging, reminders wanneer geen ICE contacten bekend zijn, etc.</p>
        <p>&nbsp;</p>

        <h6>Vergunningen</h6>
        <p>Beheer van vergunningen en medische attesten. (eventueel raadpleegbaar door personen met de rol instructeur, etc.)</p>
        <p>Herinnering vervallen medische attesten, etc.</p>
        <p>&nbsp;</p>

        <h6>Billing</h6>
        <p>Elk lid kan één of meerdere rekeningen hebben waarop uitgaven worden geboekt.</p>
        <p>Clubs hebben de mogelijkheid om één of meer soorten rekeningen te configureren.</p>
        <p>Zo kan elk lid bijvoorbeeld verschillende rekeningen: Vliegprovisie, Bar/Cafetaria, waarbord, etc.</p>
        <p>Deze module werkt met andere modules (vluchtbeheer, lidmaatschap beheer) samen om o.a. online betalingen te verwerken.</p>
        <p>&nbsp;</p>

        <h6>Lidmaatschap</h6>
        <p>Hernieuwing + betaling lidmaatschap, aansluiting Liga, etc.</p>
        <p>&nbsp;</p>

        <h6>Benieuwd naar wat we voor jou kunnen doen?</h6>


        <contact-button></contact-button>


        <p>&nbsp;</p>
        <div class="image-center">
            <img src="/assets/img/ClubFox/Clubfox_img2.jpg" alt="plaatje" width="1000px">
        </div>
`
}

import {Blog, KATRIEN, PIETER} from "./blog";

// postImg: 'assets/img/blog/img1.jpg',
//     postTitle: 'Six Ways to Make Smarter Decisions',
//     postLink: 'blog-details',
//     postDate: 'April 30, 2024',
//     postAuthorImage: 'assets/img/user1.jpg',
//     postAuthorName: 'Alex Morgan'


export const testBlog7: Blog = {
    id: "7",
    title: 'Opstart Tabbr (onder werknaam ClubFox)',
    subTitle: 'Software zweefvliegclubs, sportclubs in het algemeen',
    author: PIETER,
    date: "8 oktober 2023",
    image: 'assets/img/blog/Blog_jan2024_1_700x600.jpg',
    content: `

                        <p>Sinds 2019 ben ik lid van het Vlaams Zweefvliegcentrum Phoenix, Overboelare (VZP) en is het zweefvliegen een grote passie geworden.</p>

                        <p>Vanuit de club werd me de vraag gesteld een rewrite te doen van de interne ledensite. Voor deze rewrite ben ik op zoek gegaan naar welke software er reeds beschikbaar is op de markt. Echter bleek er niets geschikt te zijn voor onze noden.</p>

                        <p>Met mijn professionele achtergrond, ervaring en ontvangen info van mede-clubleden, heb ik een product ontwikkeld voor de club dat de noden ruimschoots dekt.<br>
                        In alle bescheidenheid durf ik zeggen dat de club hier zeer enthousiast en tevreden over is. Zij ervaren dit als een grote meerwaarde.</p>


                        <blockquote class="wp-block-quote">
                            <p>The sky is not the limit,...  it's my playground</p>
                        </blockquote>

                        <p></p>
                        <p>Laat me even kort de geplande functionaliteiten opsommen:</p>

                        <p>Software schrijven voor één club is één ding. Software schrijven die voor verschillende clubs de noden dekt, is iets heel anders.
                           We leggen de nadruk op configureerbaarheid / uitbreidbaarheid. Gewoontes of werkwijzen van een bepaalde club worden niet zomaar als 'waarheid' aangenomen, maar kunnen wél zo geconfigureerd worden.</p>

                        <p>Het geheel wordt opgezet in verschillende modules, die de clubs al dan niet kunnen activeren.
                           Modules zijn o.a.:</p>
                        <p>&nbsp;</p>

                        <h6>Personenbeheer + toegangsbeheer</h6>
                        <p>Los van de klassieke persoonsgegevens (naam, adres, contactgegevens), kunnen clubs zelf extra velden toevoegen (bv. lid sinds xxx). Ook de zichtbaarheid binnen de club is configureerbaar. (wie mag wat al dan niet zien)</p>
                        <p>Toegangsbeheer (beveiliging) is gebaseerd op “rollen” of “machtigingen”. Deze worden gedefinieerd en toegekend.</p>
                        <p>&nbsp;</p>

                        <h6>Planning</h6>
                        <p>De functionaliteit “Planning” is gelijkaardig als deze beschikbaar in Click'n Glide, met uitzondering van ride sharing.</p>
                        <p>Dit voorzien we later in een aparte module.</p>
                        <p>&nbsp;</p>

                        <h6>Vluchtenbeheer</h6>
                        <p>We voorzien hier zowel de mogelijkheid voor motorvluchten, TMG als zweefvluchten</p>
                        <p>- Toevoegen/bewerken van vluchten (manuel).</p>
                        <p>- Toevoegen vluchten uit OGN (al dan niet automatisch)</p>
                        <p>- Verrekenen vluchten (indien ook module billing geactiveerd werd). Hoe de kost berekend wordt is volledig configureerbaar.
                             De ene club kan dus een start + vluchtminuten aanrekenen, de andere club kan bv kiezen om aan te rekenen per afgerond kwartier.</p>
                        <p>- Flow “Goedkeuring vluchten voordat ze verrekend worden”, indien een club dit nodig heeft.</p>
                        <p>- Verrijking vluchten met extra velden (idem personenbeheer) zoals bv. initiatievlucht, kabelbreuk, kabelbreukoefening, etc.</p>
                        <p>&nbsp;</p>

                        <h6>Recency</h6>
                        <p>Op basis van de geregistreerde vluchten kan recency info worden berekend.</p>
                        <p>Naast de bestaande wettelijke recency-regelgeving, kunnen clubs hun eigen checks implementeren.</p>
                        <p>Bv. aan het begin van elk seizoen moet elk lid een checkvlucht en een kabelbreukoefening gedaan hebben.</p>
                        <p>&nbsp;</p>

                        <h6>Startplaats</h6>
                        <p>Deze module kan gebruikt worden om vanop de startplaats reeds de vluchtplank in te vullen.</p>
                        <p>&nbsp;</p>

                        <h6>ICE (in case of emergency)</h6>
                        <p>ICE omvat het beheer van ICE contacten. Bij welke rollen is dit verplicht, mogelijkheid tot jaarlijkse herziening/bevestiging, reminders wanneer geen ICE contacten bekend zijn, etc.</p>
                        <p>&nbsp;</p>

                        <h6>Vergunningen</h6>
                        <p>Beheer van vergunningen en medische attesten. (eventueel raadpleegbaar door personen met de rol instructeur, etc.)</p>
                        <p>Herinnering vervallen medische attesten, etc.</p>
                        <p>&nbsp;</p>

                        <h6>Billing</h6>
                        <p>Elk lid kan één of meerdere rekeningen hebben waarop uitgaven worden geboekt.</p>
                        <p>Clubs kunnen één of meer rekening types configureren.</p>
                        <p>Zo heeft elk lid 2 rekeningen: Vliegprovisie en Bar/Cafetaria.</p>
                        <p>Deze module zal in combinatie met andere modules (vluchtbeheer, lidmaatschap beheer) samenwerken om zo o.a. online betalingen etc. te verwerken.</p>
                        <p>&nbsp;</p>

                        <h6>Lidmaatschap</h6>
                        <p>Hernieuwing + betaling lidmaatschap, aansluiting Liga, etc.</p>


                        <ul class="wp-block-gallery columns-3">
                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/Blog7_okt 2023/Blog7_img 1_750x650.jpg" alt="image">
                                </figure>
                            </li>

                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/Blog7_okt 2023/Blog7_img 2_750x650.jpg" alt="image">
                                </figure>
                            </li>

                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/Blog7_okt 2023/Blog7_img 3_750x650.jpg" alt="image">
                                </figure>
                            </li>
                        </ul>

                        <!--
                        <h3>Four major elements that we offer:</h3>

                        <ul class="features-list">
                            <li><i class='bx bx-badge-check'></i> Scientific skills for getting a better result</li>
                            <li><i class='bx bx-badge-check'></i> Communication skills to getting in touch</li>
                            <li><i class='bx bx-badge-check'></i> A career overview opportunity available</li>
                            <li><i class='bx bx-badge-check'></i> A good work environment for work</li>
                        </ul>
                        -->

                        <h3>Uitbreiding</h3>
                        <p>Momenteel is de Saas ClubFox toegankelijk voor vliegclubs. Deze service is zonder twijfel aanpasbaar voor het gebruik van tal van sportclubs of verenigingen, die het beheer van clubleden, vergunningen, ... overzichtelijk willen houden. </p>

                        <!--
                        <h3>The rise of marketing and why you need it</h3>
                        -->

                        <p>We kijken er alvast naar uit om hierover in gesprek te gaan, de meerwaarde van dit product te bespreken en om van ideeën uit te wisselen.</p>



                         <div class="image-left" >
                              <img  style="margin-top:-40px" src="/assets/img/icon/tabbr_logo_transparent_background.png" alt="plaatje" width="65px">
                              <readmore-button url="product/tabbr"></readmore-button>
                         </div>
                        <p>&nbsp;</p>


                        <contact-button></contact-button>
    `

}

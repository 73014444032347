
export class HomePageBoxItem {
    icon: string;
    title: string;
    paragraphText: string;
    btnIcon: string;
    btnText: string;
    link: string;
}


export const homePage = {


    boxes: [
        {
            icon: '/assets/img/icon/Blaadje_72 px.png',
            title: 'Consultancy services',
            paragraphText: 'Het grootste deel van onze activiteiten is bedrijven en overheden bijstaan in de ontwikkeling van toepassingen, zowel back-end, front-end(web) als mobile apps. Schouder aan schouder versterken we uw teams en zorgen ervoor dat uw bedrijf mee kan evolueren in een steeds veranderende wereld. ',
            btnIcon: 'flaticon-right',
            btnText: 'Read More',
            link: 'services/consultancy'
        },
        {
            icon: '/assets/img/icon/logo_GEODesk_72 px.png',
            title: 'GEO-Desk',
            paragraphText: 'GEO-Desk is een service ontwikkeld voor laboratoria en staalnemers. Het faciliteert de samenwerking tussen staalnemers en labo. Bovendien voorziet het in een aantal specifieke noden mbt. de staalname problematiek. GEO-Desk werd ontwikkeld zodat we op maat van de klant bestaande modules kunnen aanpassen.',

            btnIcon: 'flaticon-right',
            btnText: 'Read More',
            link: 'product/geo-desk'
        },
        {
            icon: '/assets/img/icon/tabbr_logo_transparent_background.png',
            title: 'Tabbr',
            paragraphText: 'Tabbr Software is een service die voor verschillende clubs de noden dekt.We leggen de nadruk op configureerbaarheid / uitbreidbaarheid. Gewoontes of werkwijzen van een bepaalde club worden niet zomaar als waarheid aangenomen, maar kunnen wél zo geconfigureerd worden.',
            btnIcon: 'flaticon-right',
            btnText: 'Read More',
            link: 'product/tabbr'
        }
    ],

    blog: {
        sectionTitle: {
            subTitle: "Blog posts",
            title: 'In de kijker',
            paragraphText: ''
        },

        visibleItems: [
            7,
            6,
            5
        ]
    },

    testimonialSection:         {
        subTitle: "Tevreden klanten",
        title: '',
        paragraphText: 'Systemworks bv is actief in verschillende branches. Wij doen allerlei projecten, waar we trots op zijn. Een greep uit onze referenties die reeds beroep hebben gedaan op Systemworks bv.',

    },

    partners : [
        {
            img: "assets/img/partner/logo_UGent_NL_RGB_2400_kleur.png"
        },
        {
            img: "assets/img/partner/Logo Axxon.png"
        },
        {
            img: "assets/img/partner/logo_iliano.png"
        },
        {
            img: "assets/img/partner/logo_telenet.png"
        },
        {
            img: "assets/img/partner/logo_agfaHealthcare.png"
        },
        {
            img: "assets/img/partner/logo_argenta.png"
        }

    ]


}

